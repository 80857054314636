import Layout from '@/components/layout/Layout';
import { ImageAutoAlt } from '@/components/utility/ImageAutoAlt/ImageAutoAlt';
import { useAuthentication } from 'core/authentication/AuthenticationProvider';
import {
  fetchAuthenticatedNavigation,
  fetchFooterNavigation,
  fetchHomepage,
  fetchPublicNavigation,
} from 'core/strapi/StrapiApi';
import {
  IAuthenticatedWebsiteNavigation,
  IFooterNavigation,
  IHomepage,
  IPublicWebsiteNavigation,
} from 'core/strapi/types';
import { GetStaticPropsResult, NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';

type HomeProps = {
  navigationPublic: IPublicWebsiteNavigation;
  navigationAuthenticated: IAuthenticatedWebsiteNavigation;
  navigationFooter: IFooterNavigation;
  homepage: IHomepage;
};

const Home: NextPage<HomeProps> = ({
  navigationPublic,
  navigationAuthenticated,
  homepage,
  navigationFooter,
}) => {
  const { user } = useAuthentication();

  const RegisterLink = (
    <>
      <Link href="/account/register">
        <a className="btn">Get started</a>
      </Link>
      <p className="disclaimer">It&rsquo;s free, now and forever</p>
    </>
  );
  return (
    <Layout
      navigationPublic={navigationPublic}
      navigationAuthenticated={navigationAuthenticated}
      navigationFooter={navigationFooter}
    >
      <Head>
        <title>{homepage.title}</title>
      </Head>
      <main>
        <section className="hero-cta">
          <div className="container">
            <div className="copy col-8">
              <h1 className="primary">
                <span className="top">&ldquo;Let&rsquo;s kick hate</span>
                <span className="bottom">&hellip;out of school&rdquo;</span>
              </h1>
              <p className="subtext">
                Designed for primary and secondary students alike, Click Against Hate makes it
                simple to teach students about complex issues such as: online dangers, racism,
                anti-Semitism, Islamophobia, homophobia, freedom of speech, cyber bullying and more.
              </p>
              {!user && RegisterLink}
            </div>
          </div>
          <div className="bottom-graphic"></div>
        </section>

        <section className="video-with-intro">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <h2 className="h3">Our mission is to build an Australia free from hate</h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="flourish"></div>
                <div className="embed-container">
                  <iframe
                    width=""
                    height=""
                    src={
                      'https://www.youtube.com/embed/' +
                      'ZWS-XcOKqLs' +
                      '?&cc_lang_pref=en&cc_load_policy=1'
                    }
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="program-highlevel">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h3">
                  A simple way to teach,
                  <br />
                  an engaging way to learn
                </h2>
              </div>
              <div className="col-md-5">
                With ready-to-teach lessons across a variety of topics, <br />
                Click Against Hate makes educating your students about complex topics incredibly
                simple.
              </div>
            </div>
            <div className="row bottom-justified">
              <div className="col-md-4">
                <div className="selling-card">
                  <ImageAutoAlt src="" />
                  <h3 className="h4">Interactive and engaging</h3>
                  <p>
                    Make students a part of the story with interactive quizzes, videos and games
                    that take lessons from a lecture to a live experience.
                  </p>
                  <a className="bottom-cta" href="/account/register">
                    Get started
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="selling-card">
                  <ImageAutoAlt src="" />
                  <h3 className="h4">Designed for Australians, by Australians</h3>
                  <p>
                    All modules have been expertly designed for Australian students by Australian
                    teachers. There’s no generic life lessons here – all topics covered are designed
                    to confront the unique issues faced by Australian society.
                  </p>
                  <a className="bottom-cta" href="/account/register">
                    Get started
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="selling-card">
                  <ImageAutoAlt src="" />
                  <h3 className="h4">Flexible and ready-to-go</h3>
                  <p>
                    All modules feature complete lessons and are ready-to-teach. The only thing you
                    need to do is choose how and when to teach them.
                  </p>
                  <a className="bottom-cta" href="/account/register">
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <div className="row ">
              <h2 className="h4 signoff">
                All Click Against Hate content is provided{' '}
                <span className="yellow">free of charge</span>
              </h2>
            </div>
            <div className="image-bench">
              <ImageAutoAlt src="/images/website/navy-bench.svg" />
            </div>
            <div className="row">
              <div className="col-md-6 flex-top-and-bottom">
                <h2 className="h3">Teaching modules</h2>
                <a className="classic-link" href="#">
                  Learn more
                </a>
              </div>
              <div className="col-md-6">
                There’s no need to start from scratch. We’ve created ready-to-teach modules under
                four key topics: Stereotypes and predjudice, Media misrepresentation, Discrimination
                and Online hate. Each is tailored to the year level of your students to maximise
                engagement and understanding.
              </div>
            </div>
          </div>
        </section>

        <section className="proof-points">
          <div className="container">
            <h2 className="h3">Why Click Against Hate?</h2>
            <div className="row offwhite-boxout ">
              <div className="col">
                <ImageAutoAlt src="images/website/image-gov.svg" />
                <h3 className="h4">Government-backed</h3>
                <p>Click Against Hate is generously supported by the Australian Government.</p>
              </div>
              <div className="col">
                <ImageAutoAlt src="images/website/image-curriculum.svg" />
                <h3 className="h4">Mapped to the Australian Curriculum</h3>
                <p>
                  All modules are expertly designed by teaching professionals and mapped to the
                  Australian Curriculum.
                </p>
              </div>
              <div className="col">
                <ImageAutoAlt src="images/website/image-tested.svg" />
                <h3 className="h4">Tried and tested</h3>
                <p>
                  First launched in 2010, Click Against Hate has already made a difference for
                  students in hundreds of Australian schools.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="testamonials">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h3">
                  A simple way to teach,
                  <br />
                  an engaging way to learn
                </h2>
              </div>
              <div className="col-md-5">
                With ready-to-teach lessons across a variety of topics, <br />
                Click Against Hate makes educating your students about complex topics incredibly
                simple.
              </div>
            </div>
            <div className="row three-col-dividers">
              <div className="col testamonial">
                <ImageAutoAlt src="images/website/portrait-frame-1.svg" />
                <cite>
                  Kathy Van Leusen
                  <br />
                  St Catherine’s Primary
                </cite>
                <blockquote>
                  “Click Against Hate addresses serious issues in a kid friendly way. It presents
                  issues and uses terms we as teachers are often afraid to address, which I believe
                  they benefit from.”
                </blockquote>
              </div>
              <div className="col testamonial">
                <ImageAutoAlt src="images/website/portrait-frame-2.svg" />
                <cite>
                  Kathleen Tehan
                  <br />
                  Box Hill Senior Secondary College
                </cite>
                <blockquote>
                  “A well-designed platform which clarifies for students the distinction between
                  freedom of speech and forms of discrimination such as racism, vilification,
                  bullying and stereotyping.”
                </blockquote>
              </div>
              <div className="col testamonial">
                <ImageAutoAlt src="images/website/portrait-frame-3.svg" />
                <cite>
                  Liane Pimenta
                  <br />
                  St Columba’s Primary
                </cite>
                <blockquote>
                  “It gave students time to reflect on important issues such as bullying, racism and
                  violence towards women and empowered them with strategies to use when encountering
                  this online.”
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="cta-banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h2>
                  Combating hate requires commitment.
                  <br />
                  That’s why Click Against Hate is provided{' '}
                  <span className="yellow">free of charge.</span>
                </h2>
                {!user && (
                  <a className="big btn" href="/account/register">
                    Get started today
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

// runs on build to generate content passed to main render function
export const getStaticProps = async (): Promise<GetStaticPropsResult<HomeProps>> => {
  // get navigation and homepage from CMS
  const [navigationPublic, navigationAuthenticated, homepage, navigationFooter]: [
    IPublicWebsiteNavigation,
    IAuthenticatedWebsiteNavigation,
    IHomepage,
    IFooterNavigation
  ] = await Promise.all([
    fetchPublicNavigation(),
    fetchAuthenticatedNavigation(),
    fetchHomepage(),
    fetchFooterNavigation(),
  ]);
  // pass on to page as properties
  return {
    props: {
      navigationPublic,
      navigationAuthenticated,
      homepage,
      navigationFooter,
    },
    revalidate: 1,
  };
};

export default Home;
